const themeConfig = {
  TAP: {
    ApiUrl: 'https://tapis-api.tretra.org/',
    SystemStartYear: 2024,
    SystemShortName: 'Tapis',
    SystemDescription: 'Tretrácký informační systém',
    HomeClub: "TAP",
    HomeClubBankAccount: "2802097915/2010",
    TabTitle: 'Tapis – Tretrácký informační systém',
    MetaDescription: 'Informační systém KOB Tretra Praha',

    homeSection: 'Domů',
    clubSection: 'Klub',
    cupsSection: 'Žebříčky',
    myRacesSection: 'Mé závody',

    entryPageLocalCreate: 'Přihlásit se',
    entryPageRemoteCreate: 'Přihlásit se',
    entryPagePreliminaryCreate: 'Přihlásit se',
    entryPageEdit: 'Upravit přihlášku',
    entryOthersLocal: 'Přihlásit ostatní',
    entryOthersRemote: 'Přihlásit ostatní',

    servicesPage: 'Služby',

    clubRankingPage: 'Klubový ranking',

    usernamePlaceholder: 'Tretrak',
  },
  PGP: {
    ApiUrl: 'https://app-tapis-we-stage.azurewebsites.net/',
    SystemStartYear: 2024,
    SystemShortName: 'PragIS',
    SystemDescription: 'Členská zóna SK Praga',
    HomeClub: "PGP",
    HomeClubBankAccount: "???",
    TabTitle: 'PragIS – Členská zóna SK Praga',
    MetaDescription: 'Informační systém pro Pragovacký orienťák',

    homeSection: 'Kalendář akcí',
    clubSection: 'Seznam členů',
    cupsSection: '',
    myRacesSection: '',

    entryPageLocalCreate: 'Přihlásit se',
    entryPageRemoteCreate: '',
    entryPagePreliminaryCreate: '',
    entryPageEdit: 'Přihláška dopravy a ubytování',
    entryOthersLocal: 'Přihlásit ostatní',
    entryOthersRemote: '',

    servicesPage: '',

    clubRankingPage: 'Klubový ranking',

    usernamePlaceholder: 'PGP0000',
  },
};

const getSubdomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');
  if (parts.length > 2) {
    return parts[0]; // Třetí řád domény
  }
  return null;
};

const getTheme = () => {
  switch (getSubdomain()) {
    case 'tapis':
      return 'TAP'
    case 'pragis':
    case 'ca-tapis-fe':
      return 'PGP'
    default:
      return 'TAP'
  }
}

const currentTheme = getTheme();
const ApiUrl = (process.env.NODE_ENV === 'production' ? themeConfig[currentTheme].ApiUrl : 'http://localhost:8000/');
export {currentTheme, ApiUrl};
export const labels = themeConfig[currentTheme];
