import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {currentTheme, labels} from "./themeLabels";
import AppPragis from "./AppPragis";
import AppTapis from './AppTapis';
import './scss/_nightmode.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

function updateThemeAssets(theme) {
  const icon128 = document.querySelector('link[sizes="128x128"]');
  const icon192 = document.querySelector('link[sizes="192x192"]');
  const appleTouchIcon = document.querySelector('link[rel="apple-touch-icon"]');
  const manifest = document.querySelector('link[rel="manifest"]');
  const metaDescription = document.querySelector('meta[name="description"]');

  document.title = labels.TabTitle;
  if (icon128) icon128.href = `/${theme}/icon128.png`;
  if (icon192) icon192.href = `/${theme}/icon192.png`;
  if (appleTouchIcon) appleTouchIcon.href = `/${theme}/icon192.png`;
  if (manifest) manifest.href = `/${theme}/manifest.json`;
  if (metaDescription) metaDescription.content = labels.MetaDescription;
}

updateThemeAssets(currentTheme);

switch (currentTheme) {
  case 'TAP':
    import('./scss/tapis.scss').then(() => {
      root.render(<AppTapis/>);
    });
    break;
  case 'PGP':
    import('./scss/pragis.scss').then(() => {
      root.render(<AppPragis/>);
    });
    break;
  default:
    import('./scss/tapis.scss').then(() => {
      root.render(<AppTapis/>);
    });
}
