import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import React from "react";
import {labels} from "../../themeLabels";


const ModalContentAccount = () => {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Nápověda</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Modal.Title>Typy účtů</Modal.Title>
        <Table bordered>
          <tbody>
          <tr>
            <td>A</td>
            <td>aktivní účet (propojený s Orisem) s přihlašovacím přístupem do {labels.SystemShortName}u</td>
          </tr>
          <tr>
            <td>B</td>
            <td>aktivní účet (propojený s Orisem) bez přístupu do systému, někomu je třeba udělit přihlašovací práva</td>
          </tr>
          <tr>
            <td>C</td>
            <td>lokální účet {labels.SystemShortName}u, bez přístupu do systému</td>
          </tr>
          <tr>
            <td>D</td>
            <td>deaktivovaný účet {labels.SystemShortName}u, bez přístupu do systému</td>
          </tr>
          <tr>
            <td>E</td>
            <td>externí účet, který udělil přihlašovací práva v Orisu CORE správci, skrz něhož jsou prováděny přihlášky; může se přes {labels.SystemShortName} přihlašovat na závody i akce klubu</td>
          </tr>
          <tr>
            <td>F</td>
            <td>externí účet, s přístupem do {labels.SystemShortName}u, nemůže se přes {labels.SystemShortName} hlásit na závody, nicméně na akce klubu ano</td>
          </tr>
          </tbody>
        </Table>
        <Modal.Title>Role</Modal.Title>
        <Table bordered>
          <tbody>
          <tr>
            <td>U</td>
            <td>běžný uživatel bez speciálních práv</td>
          </tr>
          <tr>
            <td>T</td>
            <td>trenér; právo vytvářet a editovat akce, právo nastavovat uživatele</td>
          </tr>
          <tr>
            <td>H</td>
            <td>hospodářka; právo vstupu do účetnictví</td>
          </tr>
          <tr>
            <td>X</td>
            <td>trenér & hospodářka; kombinace práv obou skupin</td>
          </tr>
          <tr>
            <td>S</td>
            <td>správce; všechna práva</td>
          </tr>
          </tbody>
        </Table>
        <Modal.Title>Členství</Modal.Title>
        <Table bordered>
          <tbody>
          <tr>
            <td>R</td>
            <td>řadový člen</td>
          </tr>
          <tr>
            <td>P</td>
            <td>přispívající člen</td>
          </tr>
          <tr>
            <td>O</td>
            <td>ostatní člen; trenéři a aktivní činovníci klubu</td>
          </tr>
          </tbody>
        </Table>
      </Modal.Body>
    </>
  )
}

export default ModalContentAccount;