import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useParams } from "react-router-dom";
import Loader from "../../components/overlays/Loader";
import { getTransportInfo } from "../../dao";
import Card from 'react-bootstrap/Card';
import { useState } from "react";
import {faCar, faCarSide, faCircleInfo, faTruckArrowRight} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from "react-bootstrap/esm/Button";
import {handleErrorLoading, setBrowserTabText} from "../../helpers/Functions";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import Alert from "react-bootstrap/Alert";
import PageHeading from "../../layout/PageHeading";
import AutoFormat from "../../components/visual/AutoFormat";
import EventDate from "../../components/parts/EventDate";

export default function Transport(){
  const { eventId } = useParams();
  const [data, setData]= useState([]);
  const [loading, setLoading] = useState(true);
  const [errorRemote, setErrorRemote] = useState("");

  useEffect(() => {
    setLoading(true);
    getTransportInfo(eventId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json()})
      .then((response) => {
        setData(response);
        setLoading(false);
      })
  }, [eventId]);

  if (loading)
    return <Loader loading={loading} />
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  setBrowserTabText('Doprava | ' + data?.event?.title);
  return (
    <PageContainer>
      <PageHeading heading={"Klubová doprava: " + data?.event?.title} id={eventId}/>
      <p><EventDate event={data?.event} /></p>
      {data?.editor && <>
        {!data.transport_handled &&
          <Row>
            <Col>
              <Alert variant="warning">Rozdělení dopravy není zveřejněno uživatelům!</Alert>
            </Col>
          </Row>
        }
        <Row className="align-items-center">
          <Col xs="auto" className="mb-3">
            <Link to={`/akce/${eventId}/doprava/rozdeleni`}>
              <Button className="tapis-95"><FontAwesomeIcon icon={faTruckArrowRight} /> Rozdělení</Button>
            </Link>
          </Col>
          <Col xs="auto" className="mb-3">
            <Link to={`/akce/${eventId}/admin`}>Administrace akce</Link>
          </Col>
        </Row>
      </>
      }
      {data.transport_info &&
        <Row>
          <Col>
            <Alert variant="info">Info o dopravě: {data.transport_info}</Alert>
          </Col>
        </Row>
      }
      <Row className="ps-1 pe-1">
        <h3><FontAwesomeIcon icon={faCarSide} /> Vozidla</h3>
        <CarList vehicles={data?.vehicles}/>
      </Row>
    </PageContainer>
  )

  function CarList(props){
    const CarTitle = ({car}) => {
      const myTransportLink = "/akce/" + eventId + "/doprava"
      if (car.my_vehicle)
        return (<b><Link to={myTransportLink}><FontAwesomeIcon icon={faCar}/></Link> <Link to={myTransportLink}>{car.title}</Link></b>);
      return (<b><FontAwesomeIcon icon={faCar}/> {car.title}</b>);
    }

    const res = [];
    for (const i in props.vehicles) {
      const car = props.vehicles[i];
      res.push(
        <Col xs={12} sm={6} md={4} lg={3} xxl={2} className="ps-1 pe-1" key={car.id}>
          <Card className="mb-2">
            <Card.Body>
              <p className="mb-1"><CarTitle car={car} /></p>
              {car.driver != null && <Card key={car.driver.id} className="mt-2 mb-2">
                <p className="m-1 ms-2 me-2">{car.driver?.full_name}</p>
              </Card>}
              <div>
                <FontAwesomeIcon icon={faCircleInfo} color="gray" /> <AutoFormat text={car.info} />
              </div>
              <p className="mb-1"></p>
              <Crew people={car.crew} />
            </Card.Body>
          </Card>
        </Col>
      );
    }
    if (res.length < 1)
      return (<p><i>Doprava zatím nebyla rozdělena</i></p>)
    return (res);
  }

  function Crew(props){
    const crew = [];
    for (const i in props.people) {
      const person = props.people[i];
      if(person.full_name != null){
        crew.push(
          <Card key={person.id} className="mt-2">
            <p className="m-1 ms-2 me-2">{person.full_name}</p>
          </Card>
        );
      }else{
        crew.push(<Card className="mt-2" style={{borderStyle: "dotted"}}>
          <i className="m-1">Volno</i>
        </Card>);
      }
    }
    return crew;
  }
}