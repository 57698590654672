import PageContainer from "../../layout/PageContainer";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {fa1, fa2, fa3, faCoins, faMoon, faP, faRotate, faS, faSignHanging, faSun, faUser, faV} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Table from "react-bootstrap/Table";
import React, {useEffect, useState} from "react";
import {setBrowserTabText} from "../../helpers/Functions";
import Nav from "react-bootstrap/Nav";
import {Link} from "react-router-dom";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import {SelectOptionsDict} from "../../components/form/SelectOptions";
import {InterestExtendedOptions} from "../../helpers/Constants";
import Button from "react-bootstrap/Button";
import {Badge} from "react-bootstrap";
import {labels} from "../../themeLabels";


const Help = () => {
  const [tab, setTab] = useState(0);

  useEffect(() => {
    const scrollingElement = (document.scrollingElement || document.body);
    scrollingElement.scrollTop = 0;
  }, []);

  setBrowserTabText('Nápověda');
  return (
    <PageContainer>
      <h1>Nápověda</h1>
      <br/>
      <HelpCard />
    </PageContainer>
  )

  function HelpCard () {
    return (
      <Card className="mb-3">
        <Card.Header>
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link active={tab === 0} onClick={() => setTab(0)}>Domů</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link active={tab === 1} onClick={() => setTab(1)}>Akce</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link active={tab === 2} onClick={() => setTab(2)}>Přihláška</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link active={tab === 3} onClick={() => setTab(3)}>Doprava</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link active={tab === 4} onClick={() => setTab(4)}>Nástěnka</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link active={tab === 10} onClick={() => setTab(10)}>Účet</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link active={tab === 11} onClick={() => setTab(11)}>Práva</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link active={tab === 12} onClick={() => setTab(12)}>Vyúčtování</Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <SelectHelp />
      </Card>
    )

    function SelectHelp () {
      switch (tab) {
        case 1:
          return <EventHelp />;
        case 2:
          return <EnrollHelp />;
        case 3:
          return <TransportHelp />;
        case 4:
          return <DashboardHelp />;
        case 10:
          return <AccountHelp />;
        case 11:
          return <RightsHelp />;
        case 12:
          return <BillingHelp />;
        default:
          return <HomeHelp />;
      }
    }

    function HomeHelp () {
      return (
        <Card.Body>
          <Card.Title>Domovská stránka</Card.Title>
          <Row>
            <Col md>
              <p>Ikony <FontAwesomeIcon icon={faMoon} /> a <FontAwesomeIcon icon={faSun} /> slouží k přepnutí režimu na světlý/tmavý.</p>
              <b>Filtry</b>
              <p>Možnost "Doporučená" ve filtru "Oblast" je kombinace ČR+Č+P+StČ. Doporučené oblasti lze v nastavení uživatele o jednu oblast rozšířit.</p>
              <p>Pokud hledaný závod není vidět, zkontroluj, že máš zvolenou správnou kombinaci filtrů, neoficiální akce (např PPŽ) lze zobrazit jako Typ akce "Všechny".</p>
              <p>Nastavení filtrů se ukládá. Pro reset lze použít ikonu <FontAwesomeIcon icon={faRotate}/>.</p>
            </Col>
            <Col>
              <b>Indikace termínu</b>
              <Table bordered>
                <tbody>
                <tr>
                  <td><FontAwesomeIcon icon={fa1} style={{color: "LimeGreen"}}/></td>
                  <td>1. termín přihlášek</td>
                </tr>
                <tr>
                  <td><FontAwesomeIcon icon={fa2} style={{color: "Orange"}}/></td>
                  <td>2. termín přihlášek</td>
                </tr>
                <tr>
                  <td><FontAwesomeIcon icon={fa3} style={{color: "Crimson"}}/></td>
                  <td>3. termín přihlášek</td>
                </tr>
                <tr>
                  <td><FontAwesomeIcon icon={faP} style={{color: "DeepSkyBlue"}}/></td>
                  <td>Předběžné přihlášky, budou propsány do Orisu v uvedeném okamžiku</td>
                </tr>
                <tr>
                  <td><FontAwesomeIcon icon={faS} style={{color: "DeepPink"}}/></td>
                  <td>Startovky k dispozici, indikace pouze pokud byl někdo z oddílu přihlášen</td>
                </tr>
                <tr>
                  <td><FontAwesomeIcon icon={faV} style={{color: "DarkViolet"}}/></td>
                  <td>Výsledky k dispozici, indikace pouze pokud byl někdo z oddílu přihlášen</td>
                </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card.Body>
      )
    }

    function EventHelp () {
      return (
        <Card.Body>
          <Card.Title>Stránka akce</Card.Title>
          <p>Každá akce typu "akce Oris"/"trénink"/"soustředění" má svou stránku, dostupnou z kalendáře nebo stránky "Mé závody". Na stránce akce jsou uvedeny základní informace o akci, přihlášení, odkazy a rozcestník na další stránky.</p>
          <p>Akce Orisu se načítají zdvojeně – v prvním kroku záznam uložený v databázi, v druhém kroku se zpozděním pár vteřin záznam aktualizovaný dle dat v Orisu.</p>
          <p>Všechny akce typu vytvoření/úprava/smazání provedené v {labels.SystemShortName}u se ihned propisují do Orisu. Pokud je změna provedená přímo v Orisu, projeví se v {labels.SystemShortName}u nejpozději druhý den.</p>
        </Card.Body>
      )
    }

    function EnrollHelp () {
      return (
        <Card.Body>
          <Card.Title>Přihláška na akci</Card.Title>
          <p>Přihlásit se lze pouze na akce, které mají otevřené přihlášky nebo aktivované předběžně přihlašování.</p>
          <p>{labels.SystemShortName} podle informací o uživateli předvybírá číslo čipu dle typu ražení (pokud ho uživatel má uložený) a kategorii (na závody Orisu dle věku).</p>
          <p>Uživatelé bez přístupu do {labels.SystemShortName}u se mohou přihlašovat na klubové akce typu "trénink"/"soustředění". Pro přihlášky na tréninky slouží jednorázové přihlášky (zadává nějaký uživatel s přístupem do {labels.SystemShortName}u), pro přihlášky na soustředění pak anonymní přihlášky (zadávají sami i uživatelé bez přihlašovacích údajů).</p>
          <p>V přihlášce lze (pokud je na danou akci povoleno) projevit zájem o společnou dopravu či ubytování (o společné dopravě viz karta "Doprava").</p>
          <p>Pro akce typu "soustředění" je součástí přihlášky jednoduchý formulář, kde je kontrolováno vyplnění všech povinných polí.</p>
        </Card.Body>
      )
    }

    function TransportHelp () {
      return (
        <Card.Body>
          <Card.Title>Společná doprava na akce</Card.Title>
          <b><i>Zájem o dopravu / nabídka dopravy</i></b>
          <p>Pokud máš zájem o dopravu nebo nabízíš k dispozici auto pro společnou dopravu, vyber ve své přihlášce na danou akci v boxu "Společná doprava"
            příslušnou variantu.</p>
          <ul>
            <li><i>"{InterestExtendedOptions["1"]}"</i> – chci dopravu jako pasažér</li>
            <li><i>"{InterestExtendedOptions["2"]}" + počet volných míst</i> – nabízím, že vezmu auto, ale pokud ho nebude potřeba (bude dostatek míst), pak
              chci dopravu jako pasažér
            </li>
            <li><i>"{InterestExtendedOptions["3"]}" + počet volných míst</i> – nabízím, že vezmu auto vždy</li>
          </ul>
          <Row className="mb-3">
            <Col><p>Ukázka varianty "nabízím místa v autě":</p></Col>
            <Col>
              <Form.Label>Společná doprava | počet míst":</Form.Label>
              <InputGroup>
                <Form.Select style={{width: '70%'}} value={3} readOnly={true}>
                  <SelectOptionsDict options={InterestExtendedOptions}/>
                </Form.Select>
                <Form.Control readOnly={true}
                  type="number" style={{maxWidth: '30%'}} value={4}/>
              </InputGroup>
            </Col>
          </Row>
          <p>Volbu dopravy lze v přihlášce editovat až do okamžiku, kdy je někým z trenérů doprava rozdělena.</p>
          <b><i>Doprava jako pasažér</i></b>
          <p>Ve chvíli, kdy bude doprava rozdělena, pak na stránce závodu v boxu "Přihlašování" najdeš šedé tlačítko <Button
                                                                                                                             variant="secondary">Moje
            doprava</Button> .
            Po kliknutí uvidíš detail svého auta - řidiče a jeho kontakt, informace o odjezdu a ostatní členy posádky.</p>
          <p>Rozdělení posádek do všech aut po jeho zveřejnění můžeš najít pod odkazem "Doprava" v boxu "Klub". I odsud se lze podívat na detail tvého auta - kliknutím na
            odkaz v jeho názvu.</p>
          <b><i>Doprava jako řidič</i></b>
          <p>Jako řidič uvidíš na stránce v boxu "Přihlašování" najdeš šedé tlačítko <Button variant="secondary">Moje
            doprava</Button> ihned po přihlášení se na závod.</p>
          <p>Na stránce "Moje doprava" pak můžeš doplnit informaci o odjezdu nebo přidat si sám/sama členy posádky (ze seznamu všech zájemců o dopravu, lze pouze
            do rozdělení dopravy trenéry). Zároveň tu také najdeš kontakty na členy své posádky.</p>
          <p>Rozdělení posádek do všech aut po jeho zveřejnění můžeš najít pod odkazem "Doprava" v boxu "Klub". I odsud se lze podívat na detail tvého auta - kliknutím na
            odkaz v jeho názvu.</p>
        </Card.Body>
      )
    }

    function DashboardHelp () {
      return (
        <Card.Body>
          <Card.Title>Klubová nástěnka</Card.Title>
          <p>Klubová nástěnka slouží ke zveřejňování oznámení a krátkých textových příspěvků pro všechny uživatele s přístupem do systému.</p>
          <p>Odkaz na klubovou nástěnku najdeš na stránce "{labels.clubSection}", běžní členové i na horní liště. Pokud byl přidán nový příspěvek, pak u odkazu bude svítit počet nezobrazených příspěvků, např. <FontAwesomeIcon icon={faSignHanging}/> Nástěnka <Badge style={{fontSize: "0.9em"}} pill bg="warning">{1}</Badge>.
            Příspěvek se počítá jako zobrazený, pokud si načteš stránku <Link to="/klub/nastenka">Nástěnka</Link>.</p>
          <p></p>
        </Card.Body>
      )
    }

    function AccountHelp () {
      return (
        <Card.Body>
          <Card.Title>Nastavení účtu</Card.Title>
          <p>Každý uživatel si může upravit některé vlastnosti svého účtu v <Link to="/ucet/nastaveni">Nastavení</Link>.</p>
          <p>Nastavit a změnit lze:</p>
          <ul>
            <li>čísla čipů – čísla pak budou nabízena v přihlašovacím formuláři</li>
            <li>IOF ID – nezbytné pro přihlašovaní do kategorií zařazených do WRE</li>
            <li>oblast navíc k doporučeným - oblast, která bude navíc zahrnuta na domovské stránce do filtru "Doporučená" oblast k ČR+Č+P+StČ</li>
            <li>barevné označení úrovně závodů - indikátory zvýrazňující béčka, republikové a štafetové závody</li>
            <li>uživatelské jméno</li>
            <li>heslo</li>
            <li>primární email – na tento email budou zasílány generované maily z {labels.SystemShortName}u</li>
            <li>sekundární email</li>
            <li>telefonní číslo – jako kontakt v případě potřeby, viditelné pouze pro řidiče aut ze společné dopravy a trenéry</li>
          </ul>
        </Card.Body>
      )
    }

    function RightsHelp () {
      return (
        <Card.Body>
          <Card.Title>Práva mezi účty</Card.Title>
          <p>Mezi účty lze nastavovat dva typy práv – <b>přihlašovací práva</b> a <b>přidružení</b>. Přehled práv je uveden v sekci <Link
            to="/ucet/opravneni">Oprávnění</Link>. Nastavování práv je možné v sekci <Link to="/ucet/prava">Práva</Link>.</p>
          <b>Přihlašovací práva</b>
          <p>Každý uživatel (případně administrátor) může udělit právo být přihlašován jiným uživatelem na jakékoliv akce. Právo je uděleno do odvolání.</p>
          <b>Přidružení</b>
          <p>Jakýkoliv účet lze přidružit k nadřazenému účtu. Toto právo může přidat pouze administátor. Přidružení může zrušit jak nadřazený, tak přidružený účet. Zrušení přidružení bude platit vždy od 31. prosince aktuálního roku, v průběhu ledna lze ještě přidružení zrušit ke konci uplynulého roku.</p>
          <p>Všechny náklady přidružených účtů se přičítají k nadřazenému účtu, a všechny platby musí být identifikované variabilním symbolem pro nadřazený účet.</p>
          <p></p>
        </Card.Body>
      )
    }

    function BillingHelp () {
      return (
        <Card.Body>
          <Card.Title>Vyúčtování</Card.Title>
          <p>Každý uživatel najde své osobní vyúčtování v sekci <Link to="/ucet/vyuctovani">Vyúčtování</Link>.</p>
          <p>Stránka s vyúčtováním obsahuje aktuální dluh uživatele, rozpočítané položky pro přidružené účty, a odkaz na podrobné položkové vyúčtování.</p>
          <b>Aktualizace stránky</b>
          <p>Nové pohledávky se mohou na stránce objevovat takto:</p>
          <ul>
            <li>pohledávky za ORIS akce – připočtou se hromadně po skončení části sezóny, uživatelé budou notifikováni</li>
            <li>klubové pohledávky – připočtou se ihned ve chvíli, kdy jsou pověřenou osobou nahrány do systému</li>
          </ul>
          <p>Platby jsou nahrávány v intervalu přibližně jedenkrát měsíčně, na stránce s vyúčtováním je uvedeno datum přijetí poslední nahrané platby.</p>
          <p>Pokud má uživatel dluh (suma nahraných plateb neodpovídá sumě pohledávek), pak má ikona sekce <FontAwesomeIcon icon={faCoins} color="red"/> Vyúčtování a ikona u jména <FontAwesomeIcon icon={faUser} color="red"/> červenou barvu.</p>
          <b>Placení závodů pořadateli</b>
          <p>Na všech závodech, na které se uživatelé hlásí formou Orisu/{labels.SystemShortName}u, <b>platí startovné hromadně klub</b> a je poté vyúčtováno, včetně zimních lig, oblastních závodů "cizích" oblastí atp..</p>
          <b>Doplatky</b>
          <p>Pro řadové členy (nikoliv pro přispívající) existují z motivačních důvodů a šetření zbytných výdajů na akcích, které klub platí v rámci členských příspěvků, dva druhy doplatků:</p>
          <ul>
            <li>za pozdní přihlášení – pokud se uživatel přihlásí v pozdějším než prvním termínu, pak je mu/jí připočten rozdíl mezi těmito částkami</li>
            <li>za neúčast – pokud se uživatel přihlásí na závody, kterých se poté nezúčastní, pak je mu/jí připočteno celé startovné, první dvě neúčasti v roce jsou omluveny a nepočítají se</li>
          </ul>
        </Card.Body>
      )
    }
  }
}

export default Help;