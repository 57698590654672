import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link, useLocation, useParams} from "react-router-dom";
import { getEnrollInfo, deleteEnroll, postEnroll} from "../../dao";
import React, { useState, useEffect, useRef } from 'react';
import {SelectOptionsClasses, SelectOptionsDict, SelectOptionsSet} from "../../components/form/SelectOptions";
import { useNavigate } from "react-router-dom";
import {faCircleChevronLeft, faHotel, faInfoCircle, faLightbulb, faQuestionCircle, faTriangleExclamation} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {handleErrorLoading, handleErrorSubmit, setBrowserTabText} from "../../helpers/Functions";
import {isMobileDevice} from "../../helpers/Device";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import {HomeClub, InterestBasicOptions, InterestExtendedOptions, PunchingOptions} from "../../helpers/Constants";
import Loader from "../../components/overlays/Loader";
import Alert from "react-bootstrap/Alert";
import InputGroup from "react-bootstrap/InputGroup";
import OverlayTooltip from "../../components/overlays/OverlayTooltip";
import FormField from "../../components/form/FormField";
import EnrollCamp from "./EnrollCamp";
import EventDate from "../../components/parts/EventDate";
import Modal from "react-bootstrap/Modal";
import {labels} from "../../themeLabels";

const Enroll = () => {
  const { eventId } = useParams();
  const { userId } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const [si, setSi] = useState("");
  const [siNumber, setSiNumber] = useState("");
  const [category, setCategory] = useState("");
  const [note, setNote] = useState("");
  const [clubNote, setClubNote] = useState("");
  const [carCapacity, setCarCapacity] = useState(null);
  const [carInfo, setCarInfo] = useState("");
  const [requestedStart, setRequestedStart] = useState("");
  const [transport, setTransport] = useState(0);
  const [accommodation, setAccommodation] = useState(0);
  const [stagesSelected, setStagesSelected] = useState(0);
  const [oneTimeEntry, setOneTimeEntry] = useState(location.state?.oneTimeEntry);
  const [oneTimeName, setOneTimeName] = useState("");
  const [oneTimeSurname, setOneTimeSurname] = useState("");
  const [showSITip, setShowSITip] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const target = useRef(null);

  useEffect(() => {
    loadEnrollInfo();
  }, [userId, eventId, oneTimeEntry]);

  function loadEnrollInfo(){
    setLoading(true);
    getEnrollInfo(eventId, userId, oneTimeEntry)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json()})
      .then((response) => {
        setData(response);
        if (response?.event?.transport === 1) {
          setTransport(1);
        }
        if (!oneTimeEntry)
          setSi(siCardOptions(response?.user?.si_cards)[0].value);
        if (response?.user?.classes.length > 0)
          setCategory(preselectClass(response?.user?.classes));
        if (response?.event?.accommodation === 1) {
          setAccommodation(1);
        }
        if (response?.event?.stages_count > 0) {
          if (!response?.applied)
            setStagesSelected(Math.pow(2, response.event.stages_count + 1) - 1)
          else
            setStagesSelected(response?.detail?.stages)
        }
        if (response?.applied) {
          const source = response?.event?.type === 2 ? response.detail.submission : response.detail;
          if(source.rent_si){
            setSi('rent');
          }else{
            if(isSiCustom(response.user, source)){
              setSi('custom');
              setSiNumber(source.si_number);
            }else{
              setSi(source.si_number);
            }
          }
          setNote(source.note);
          setClubNote(response.detail.club_note);
          setRequestedStart(response.detail.start_request);
          setCategory(response.detail.class_id+"");
          setTransport(source.transport);
          setAccommodation(response.detail.accommodation);
          if(source.car_capacity > 0){
            setCarCapacity(source.car_capacity);
          }
        } else {
          if (data?.event?.term < 0)
            setErrorRemote("Přihlášky nejsou otevřeny...");
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));

    function isSiCustom(user, detail){
      var res = true;
      user.si_cards.forEach((card)=> {
        if(detail.si_number === card.si_number){
          res = false;
        }
      });
      return res;
    }
  }

  let handleSubmit = async (e) => {
    e.preventDefault();
    if (si === 'custom' && siNumber === "") {
      return;
    }
    var rentSi = si === 'rent';
    var o_si;
    if(si === 'rent'){
      o_si = 0;
    }else if(si === 'custom'){
      o_si = siNumber;
    }else{
      o_si = si;
    }
    var mode = data.applied ? "PUT" : "POST";

    var res = await postEnroll(eventId, data.user.id, category, getCategoryName(category, data.user.classes),
      o_si, rentSi, requestedStart, note, clubNote, carCapacity, carInfo, transport, accommodation, stagesSelected, oneTimeEntry, oneTimeName, oneTimeSurname, mode);
    let inReserves = (data.event.entry_count > data.event.entry_limit) && data.event.entry_limit !== 0;
    if(res.ok){
      (mode === "POST") ? setSuccess(inReserves ? "Přihláška mezi náhradníky přidána" : "Přihláška přidána") : setSuccess("Přihláška upravena");
      setOneTimeName("");
      setOneTimeSurname("");
      loadEnrollInfo();
    }else{
      setError(handleErrorSubmit(res, (mode === 'POST' ? "Nepodařilo se přihlásit" : "Nepodařilo se upravit přihlášku")));
    }
  };

  function redirectToUserList(value){
    if (value==="others") {
      navigate("/akce/"+eventId,{state:{showEnrollList:true}});
    }
  }
  const CreatorEditorText = ({person, label}) => {
    const labelText = label + ": ";
    if (person == null)
      return "";
    return <i>{labelText} {person.reg_number} – {person.full_name}</i>;
  }

  const TopButtons = () => {
    return (<Col className="text-end">
      {data.event?.type !== 0 && <Button className="mb-2 ms-3" variant="link" onClick={() => setOneTimeEntry(!oneTimeEntry)}>{oneTimeEntry ? "Běžná přihláška" : "Jednorázová přihl" + (isMobileDevice() ? "." : "áška")}</Button>}
      {(data.event?.type === 0 && data.event?.services) && <Link to={`/akce/${eventId}/sluzby`}><Button className="mb-2 ms-3"><FontAwesomeIcon icon={faHotel}/> Doplňkové služby</Button></Link>}
      <Link to={`/akce/${eventId}`}><Button variant="secondary" className="mb-2 ms-3"><FontAwesomeIcon icon={faCircleChevronLeft} /> Stránka akce</Button></Link>
    </Col>)
  }

  if (loading)
    return <Loader />
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />
  if(data.event.type === 2)
    return <EnrollCamp data={data} si={si} siNumber={siNumber} note={note} transport={transport} carCapacity={carCapacity}/>

  const basicLimitReached = data.event?.entry_limit > 0 && data.event.entry_count >= data.event.entry_limit;
  const extendedLimitReached = data.event?.entry_limit > 0 && data.event.entry_count >= data.event.entry_limit + data.event.entry_reserves;

  setBrowserTabText('Přihláška | ' + data?.event?.title);
  return (
    <PageContainer background={getBackground()}>
      <Row>
        <Col xl={8} lg={12}>
          <h1>{oneTimeEntry ? "Jednorázová přihláška:" : (data?.event?.term === 0 ? "Předběžná přihláška:" : "Přihláška:")} {data?.event?.title}</h1>
        </Col>
        <TopButtons/>
      </Row>
      <Row>
        <Col><p className="mb-2"><EventDate event={data?.event} /></p></Col>
        {data.event?.entry_limit > 0 && (isMobileDevice() ?
          <Col>{data.event.entry_count}/{data.event.entry_limit}+{data.event.entry_reserves}</Col> :
          <Col>
            <Row>
              <Col>Přihlášeno: {data.event.entry_count}</Col>
              <Col>Limit + náhradníci: {data.event.entry_limit} + {data.event.entry_reserves}</Col>
            </Row>
          </Col>)
        }
      </Row>
      {oneTimeEntry && <Alert variant="warning">Jednorázová přihláška je určena pouze pro osoby, které nejsou členy klubu, a tedy nemají účet v {labels.SystemShortName}u!</Alert>}
      {!data.applied ? (extendedLimitReached ? <Alert variant="danger">Kapacita naplněna!</Alert> : <></>) :
        (data?.detail?.reserve_idx > 0 ? <Alert variant="warning">Přihlášen/a jako náhradník #{data?.detail?.reserve_idx}</Alert> : <br/>)}
      {(data.applied && basicLimitReached) && <Alert variant="warning">
        <FontAwesomeIcon icon={faTriangleExclamation}/> Z důvodu omezení na straně Orisu je nutné změnu kategorie dělat jako odhlášku a novou přihlášku. Touto metodou bude tvoje přihláška přesunuta na poslední náhradnické místo. Pro bezpečnou změnu kategorie můžeš kontaktovat administrátora, ostatní změny lze provádět bez nutnosti odhlášky.</Alert>}
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            { oneTimeEntry ?
              <Form.Group className="mb-3" controlId="blankUser">
                <Form.Label>Jméno | Příjmení:</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text" style={{ width: '50%' }} disabled={data?.event?.term < 0}
                    value={oneTimeName} onChange={(e) => setOneTimeName(e.target.value)} />
                  <Form.Control
                    type="text" style={{ width: '50%' }} disabled={data?.event?.term < 0}
                    value={oneTimeSurname} onChange={(e) => setOneTimeSurname(e.target.value)}/>
                </InputGroup>
              </Form.Group> :
              <Form.Group className="mb-3" controlId="user">
                <Form.Label>Uživatel:</Form.Label>
                <Form.Select defaultValue={"me"} disabled={data?.event?.term < 0} onChange={(e) => redirectToUserList(e.target.value)}>
                  <option value="me">{[data?.user?.full_name + " - " + data?.user?.reg_number]}</option>
                  <option value="others">Přihlásit ostatní</option>
                </Form.Select>
            </Form.Group>
            }
          </Col>
          <Col md>
            <Form.Group className="mb-3" controlId="class">
              <Form.Label>Kategorie:</Form.Label>
              <Form.Select value={category} disabled={data?.applied && (!data.detail?.delete_person || basicLimitReached)}
                           onChange={(e) => {setCategory(e.target.value)}}>
                <SelectOptionsClasses options={data?.user?.classes} type={data?.event?.type}/>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md>
            <Form.Group className="mb-3" controlId="si">
              <Row>
                <Col xs="auto"><Form.Label>Čip:</Form.Label></Col>
                <Col className="text-end">Ražení: {PunchingOptions[data?.event?.punching]}</Col>
              </Row>
              <Form.Select value={si}
                           disabled={data?.applied && !data.detail?.edit_person}
                           onChange={(e) => {
                             setSi(e.target.value);
                             setShowSITip(false);
                           }}>
                <SelectOptionsSet options={siCardOptions(data?.user?.si_cards)}/>
              </Form.Select>
            </Form.Group>
            {si === "custom" && (<><Form.Group className="mb-3" controlId="siNumber">
              <Form.Label id="card-label">Číslo čipu: <Link to="#" ref={target} onClick={() => setShowSITip(!showSITip)}><FontAwesomeIcon icon={faLightbulb} color="gold"/></Link>
              </Form.Label>
              <OverlayTooltip content={"Své čipy si můžeš uložit v nastavení účtu"} target={target.current} show={showSITip}/>
              <Form.Control type="number" isInvalid={siNumber === ""}
                            disabled={data?.applied && !data.detail?.edit_person}
                            value={siNumber} onChange={(e) => {setSiNumber(e.target.value)}}/>

            </Form.Group></>)
            }
          </Col>
        </Row>
        {data?.event?.stages_count > 0 && <StageSelection/>}
        <hr/>
        {data?.event?.type === 0 && <>
          <Row>
            <Col>
              <FormField label={"Poznámka ORIS"} value={note} setValue={setNote} valueLimit={256}
                         disabled={data?.applied && !data.detail?.edit_person} controlId={"note"} />
            </Col>
            <Col md>
              <FormField label={"Požadovaný start"} value={requestedStart} setValue={setRequestedStart} valueLimit={256}
                         disabled={(data?.applied && !data.detail?.edit_person) || data?.event?.start_request_forbidden} controlId={"startRequest"} />
            </Col>
          </Row>
          <hr/>
        </>}
        {data?.event?.club_options_notice ?
          <p><i>Pro přihlášení se ke společnému ubytování a dopravě (pokud jsou vypsány) použij přihlášku na závod předchozí den.</i></p> :
          <Row>
            <Col md={5}>
              <Form.Group className="mb-3" controlId="accommodation">
                <Form.Label>Klubové ubytování:</Form.Label>
                <Form.Select
                  disabled={data?.event?.accommodation !== 0 || (data?.applied && !data.detail?.edit_transport)}
                  value={accommodation} onChange={(e) => setAccommodation(Number(e.target.value))}>
                  <SelectOptionsDict options={InterestBasicOptions} />
                </Form.Select>
              </Form.Group>
              {data?.event?.accommodation_info !== "" && <p><FontAwesomeIcon icon={faInfoCircle} color="gray"/> <i>{data?.event?.accommodation_info}</i></p>}
            </Col>
            <Col md={7}>
              <Form.Group className="mb-3" controlId="transport">
                <Form.Label>Společná doprava{transport > 1 && " | počet míst"}:</Form.Label>
                <InputGroup>
                  <Form.Select style={{ width: '70%' }}
                               disabled={data?.event?.transport !== 0 || (data?.applied && !data.detail?.edit_transport)}
                               value={transport} onChange={(e) => setTransport(Number(e.target.value))}>
                    <SelectOptionsDict options={InterestExtendedOptions} />
                  </Form.Select>
                  {transport > 1 &&
                    <Form.Control
                      type="number" style={{ maxWidth: '30%' }} isInvalid={carCapacity == null}
                      disabled={data?.event?.transport !== 0 || (data?.applied && !data.detail?.edit_transport)}
                      value={carCapacity} onChange={(e) => {
                      setCarCapacity(Math.min(9, Math.max(1, Number(e.target.value) % 10)))
                    }}/>
                  }
                </InputGroup>
              </Form.Group>
              {data?.event?.transport_info !== "" && <p><FontAwesomeIcon icon={faInfoCircle} color="gray"/> <i>{data?.event?.transport_info}</i></p>}
              {(transport > 1 && !data.applied) &&
                <FormField label={"Mé info k autu"} value={carInfo} setValue={setCarInfo} valueLimit={128} controlId={"carInfo"} />
              }
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <FormField label={"Poznámka " + HomeClub} value={clubNote} setValue={setClubNote} valueLimit={256} controlId={"clubNote"} />
          </Col>
        </Row>
        <Row>
          {data?.applied &&
            <Col md className="mb-2">
              <CreatorEditorText person={data.detail.created_by} label={"Vytvořil/a"}/><br/>
              <CreatorEditorText person={data.detail.edited_by} label={"Upravil/a"}/>
            </Col>
          }
          <Col className="text-end">
            <EnrollDelete/>
            <EnrollSubmit/>
          </Col>
        </Row>
      </Form>
      <br/>
      <Modal show={confirmDelete} onHide={() => setConfirmDelete(false)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Skutečně smazat přihlášku?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmDelete(false)}>Zavřít</Button>
          <Button variant="primary" onClick={tryDeleteEnroll}>Smazat</Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={success} handleClose={() => setSuccess("")}/>
    </PageContainer>
  );

  function StageSelection() {
    function StageCheckboxes() {
      const handleInputChange = (parameter, value) => {
        const change = Math.pow(2, parameter);
        (value) ? setStagesSelected(stagesSelected + change) : setStagesSelected(stagesSelected - change);
      };

      const stages = data?.event.stages_count;
      const rows = [];
      for(let idx = 1; idx <= stages; idx++) {
        let label = "Etapa " + idx;
        rows.push(<Col xs="auto" key={"st-"+idx}>
          <Form.Check inline key={"stage-"+idx} type="checkbox" label={label} defaultChecked={(stagesSelected & (1 << idx)) !== 0}
                      disabled={data?.event?.all_stages_only || data?.applied}
                      onChange={(e) => {handleInputChange(idx, e.target.checked)}}/>
        </Col>);
      }
      return(rows);
    }
    return (<Form.Group className="mb-3" controlId="form"><Row><StageCheckboxes/></Row></Form.Group>)
  }

  function siCardOptions(siCards){
    var res = [];
    siCards?.forEach((card) => {
      res.push({name: card.si_number, value: card.si_number});
    });
    res.push({name: "Půjčit čip", value: "rent"});
    res.push({name: "Neuložený čip", value: "custom"});
    return res;
  }
  function preselectClass(classes) {
    for (const c in classes) {
      const cls = classes[c];
      if (cls?.preselect) {
        return cls.id;
      }
    }
    return classes[0].id;
  }

  function getCategoryName(id, categories){
    var res;
    categories?.forEach((category)=>{
      if ((category.id + "") === id)
        res = category.name;
    });
    return res;
  }
  function EnrollSubmit(){
    if(data?.applied){
      return(<Button style={{"marginLeft": "10px"}} variant="primary" type="submit">Upravit přihlášku</Button>);
    }
    if (data.event.term === 0)
      return(<Button variant="info" disabled={data.user.classes.length === 0 || (transport > 1 && carCapacity == null)} type="submit" style={{fontSize: "1.2em"}}>Předběžně přihlásit</Button>);
    return(<Button variant="primary" disabled={extendedLimitReached || data.user.classes.length === 0 || (transport > 1 && carCapacity == null)} type="submit" style={{fontSize: "1.2em"}}>Přihlásit</Button>);
  }
  function EnrollDelete(){
    if(data?.applied && data?.detail?.delete_person){
      return(<Button variant="secondary" onClick={() => setConfirmDelete(true)}>Smazat přihlášku</Button>);
    }
  }
  function tryDeleteEnroll(){
    deleteEnroll(eventId, userId)
      .then((response)=>{
        if (response.ok) {
          setSuccess("Přihláška smazána");
          loadEnrollInfo();
        } else {
          setError(handleErrorSubmit(response, "Nepodařilo se smazat"));
        }
        setConfirmDelete(false);
      })
  }
  function getBackground(){
    if(data?.applied){
      if (data.detail.term === 0)
        return "rgba(112,230,253,0.2)";
      if (data.detail?.reserve_idx > 0)
        return "rgba(200,190,20,0.2)";
      return "rgba(2,200,30,0.2)";
    }
    return "rgba(0,0,0,0)";
  }
};

export default Enroll;