import React, {useEffect, useState} from "react";
import {getServerActionLog} from "../../dao";
import {filterByFullName, handleErrorLoading, parseDateTimeSec, setBrowserTabText} from "../../helpers/Functions";
import Loader from "../../components/overlays/Loader";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import Table from "react-bootstrap/Table";
import FormField from "../../components/form/FormField";
import {isMobileDevice} from "../../helpers/Device";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotate} from "@fortawesome/free-solid-svg-icons";
import PageSwitch from "../../components/visual/PageSwitch";


const ServerActionLog = () => {
  const [data, setData] = useState([]);
  const [date, setDate] = useState((new Date()).toISOString().split('T')[0]);
  const [errorRemote, setErrorRemote] = useState("");
  const [loading, setLoading] = useState(true);
  const [author, setAuthor] = useState("");
  const [method, setMethod] = useState("");
  const [entity, setEntity] = useState("");
  const [eventId, setEventId] = useState("");
  const [userId, setUserId] = useState("");
  const [page, setPage] = useState(1);

  const rowsPerPage = 100;

  useEffect(() => {
    setLoading(true);
    getServerActionLog(date)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [date]);

  if (loading)
    return <Loader />;
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;

  setBrowserTabText('Log akcí');
  return (
    <PageContainer>
      <h1>Log akcí</h1>
      {EmailQueueTable() }
    </PageContainer>
  )

  function EmailQueueTable() {
    const filteredRows = [];
    const rows = [];

    for (const i in data?.log) {
      const element = data?.log[i];
      if (author !== "") {
        if (filterByFullName(author, [element.author]).length === 0 && !(element.author.id+"").includes(author))
          continue;
      }
      if (method !== "") {
        if (!element.method.toUpperCase().includes(method.toUpperCase()))
          continue;
      }
      if (entity !== "") {
        const split = entity.split(' ')
        const entityName = split[0];
        const entityId = split.length > 1 ? split[1] : split[0]
        if (split.length > 1) {
          if (!(element.entity.toUpperCase().includes(entityName.toUpperCase()) && (element.entity_id+"").includes(entityId)))
            continue;
        } else {
          if (!(element.entity.toUpperCase().includes(entityName.toUpperCase()) || (element.entity_id+"").includes(entityId)))
            continue;
        }
      }
      if (eventId !== "") {
        if (!(element.related_event_id+"").includes(eventId))
          continue;
      }
      if (userId !== "") {
        if (!(element.related_profile_id+"").includes(userId))
          continue;
      }
      filteredRows.push(element);
    }
    let rowPage = filteredRows.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    if (rowPage.length === 0) {
      rowPage = filteredRows.slice(0, rowsPerPage);
    }
    for (const i in rowPage) {
      const element = rowPage[i];
      rows.push(
        <tr key={i}>
          {isMobileDevice() ? <>
            <td>{element.author?.id}<hr className="mt-1 mb-1"/>{element?.id}</td>
            <td>{element.author?.full_name}<br/><i>{element.author?.reg_number}</i></td>
            <td>{element.method}<br/><i>{element.operation.substring(0, 1)}</i></td>
            <td>{element.entity}<br/>{element.entity_id}</td>
          </> : <>
            <td>{element?.id}</td>
            <td>{element.author?.id}</td>
            <td>{element.author?.reg_number}</td>
            <td>{element.author?.full_name}</td>
            <td>{element.method}</td>
            <td>{element.operation.substring(0, 1)}</td>
            <td>{element.entity}</td>
            <td>{element.entity_id}</td>
          </>}
          <td>{element?.related_event_id && <Link className="no-decoration" to={`/akce/${element?.related_event_id}`} target="_blank">{element?.related_event_id}</Link>}</td>
          <td>{element?.related_profile_id && <Link className="no-decoration" to={`/ucet/${element?.related_profile_id}`} target="_blank">{element?.related_profile_id}</Link>}</td>
          <td>{parseDateTimeSec(element?.timestamp, false)}</td>
        </tr>);
    }

    return (
      <>
        {rows.length !== 0 && <PageSwitch page={page} setPage={setPage} rowsPerPage={rowsPerPage} rowCount={filteredRows.length} />}
        <Table size="sm" striped bordered hover responsive>
          <thead>
          <tr>
            {!isMobileDevice() && <th>#</th>}
            <th>ID</th>
            {isMobileDevice() ? <>
              <th>Autor</th>
              <th>Metoda</th>
              <th>Entita</th>
            </> : <>
              <th>Reg</th>
              <th>Autor</th>
              <th>Metoda</th>
              <th>Op</th>
              <th>Entita</th>
              <th>No.</th>
            </>}
            <th>Akce</th>
            <th>Účet</th>
            <th></th>
          </tr>
          <tr>
            <td className="align-center pointing"><FontAwesomeIcon icon={faRotate} onClick={() => {
              setAuthor("");
              setMethod("");
              setEntity("");
              setEventId("");
            }}/></td>
            {isMobileDevice() ? <td>
              <FormField label="" value={author} setValue={setAuthor} digits="none" className="mb-0" controlId="author"/>
            </td> : <>
              <td colSpan={3}>
                <FormField label="" value={author} setValue={setAuthor} digits="none" className="mb-0" controlId="author"/>
              </td>
            </>}
            <td><FormField label="" value={method} setValue={setMethod} digits="none" className="mb-0" controlId="method"/></td>
            {isMobileDevice() ? <td>
              <FormField label="" value={entity} setValue={setEntity} digits="none" className="mb-0" controlId="entityName"/>
            </td> : <>
              <td></td>
              <td colSpan={2}>
                <FormField label="" value={entity} setValue={setEntity} digits="none" className="mb-0" controlId="entityName"/>
              </td>
            </>}
            <td><FormField label="" value={eventId} setValue={setEventId} digits="none" className="mb-0" controlId="eventId"/></td>
            <td><FormField label="" value={userId} setValue={setUserId} digits="none" className="mb-0" controlId="userId"/></td>
            <td><FormField label="" type={"date"} value={date} setValue={setDate} className="mb-0" controlId="dateShown"/></td>
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </Table>
        {rows.length === 0 ? <p><i>Žádné procesy zaznamenány</i></p> : <PageSwitch page={page} setPage={setPage} rowsPerPage={rowsPerPage} rowCount={filteredRows.length} />}
      </>
    );
  }
}

export default ServerActionLog;